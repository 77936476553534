<template>
  <f7-page name="scan-qr">
    <f7-navbar title="Scan QR" back-link="Back"></f7-navbar>

<div class="app__layout">
    <main class="app__layout-content">
      <video autoplay></video>

      <!-- Dialog  -->
      <div class="app__dialog app__dialog--hide">
        <div class="app__dialog-content">
          <h5>Gift QR Code</h5>
          <input type="text" id="result">
        </div>
        <div class="app__dialog-actions">
<!--          <button type="button" class="app__dialog-open">Open</button>-->
          <button type="button" class="app__dialog-close">Close</button>
        </div>
      </div>

      <div class="app__dialog-overlay app__dialog--hide"></div>

      <!-- Snackbar -->
      <div class="app__snackbar"></div>
    </main>
</div>

    <div class="app__overlay">
      <div class="app__overlay-frame"></div>
      <!-- Scanner animation -->
      <div class="custom-scanner"></div>
      <div class="app__help-text"></div>
    </div>

    <div class="app__select-photos"></div>

    <f7-popup v-if="popupOpened" class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false;hideDialog()">
      <f7-page>
        <f7-navbar  :title="currentGiftCard.gift_card.name">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <gift-card :account="account" :base-url="baseUrl" :issued-gift-card="currentGiftCard"></gift-card>

      </f7-page>
    </f7-popup>

  </f7-page>
</template>

<script>

    import QRReader from '../components/utils/qrscan';
    import { snackbar } from'../components/utils/snackbar';
    import GiftCard from "../components/GiftCard";
    import { mapState } from 'vuex'

    import isURL from 'is-url';


    //If service worker is installed, show offline usage notification
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(reg => {
                    console.log('SW registered: ', reg);
                    if (!localStorage.getItem('offline')) {
                        localStorage.setItem('offline', true);
                        snackbar.show('App is ready for offline usage.', 5000);
                    }
                })
                .catch(regError => {
                    console.log('SW registration failed: ', regError);
                });
        });
    }


    let copiedText = null;
    let frame = null;
    let selectPhotoBtn = null;
    let dialogElement =   null;
    let dialogOverlayElement =  null;
    let dialogOpenBtnElement =  null;
    let dialogCloseBtnElement =  null;
    let scanningEle =  null;
    let textBoxEle =  null;
    let helpTextEle =  null;
    let infoSvg =  null;
    let videoElement =  null;
    window.appOverlay =  null;




  export default {
    components: {GiftCard},
    comments:{
      GiftCard
    },
      data(){

          return {
              frame:null,
              loading:false,
              popupOpened:false,
              qrCodeText:""
          }
      },

    computed:{
        ...mapState([
            "currentGiftCard",
            "account",
            "baseUrl"
        ])

    },

    watch:{
      qrCodeText(val){
        if(val){
        const app = this.$f7;
        this.openLoader(val)
        this.$store.dispatch("loadGiftCard",val).then(res=>{
          this.closeLoader()
          this.popupOpened=true;
        }).catch(error=>{
          this.closeLoader()
          app.dialog.alert(`Gift Card having code ${val} not found`,"Validation error", () => {
            this.hideDialog()

          });
        })

        }

      }
    },

      mounted(){
          //To check the device and add iOS support
          window.iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
          window.isMediaStreamAPISupported = navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices;
          window.noCameraPermission = false;


           selectPhotoBtn = document.querySelector('.app__select-photos');
           dialogElement = document.querySelector('.app__dialog');
           dialogOverlayElement = document.querySelector('.app__dialog-overlay');
           // dialogOpenBtnElement = document.querySelector('.app__dialog-open');
           dialogCloseBtnElement = document.querySelector('.app__dialog-close');
           scanningEle = document.querySelector('.custom-scanner');
           textBoxEle = document.querySelector('#result');
           helpTextEle = document.querySelector('.app__help-text');
           infoSvg = document.querySelector('.app__header-icon svg');
           videoElement = document.querySelector('video');
          window.appOverlay = document.querySelector('.app__overlay');



          //Dialog close btn event
          dialogCloseBtnElement.addEventListener('click', this.hideDialog, false);
          // dialogOpenBtnElement.addEventListener('click', this.openInBrowser, false);


          //Initializing qr scanner
          QRReader.init(); //To initialize QR Scanner
          // Set camera overlay size
          setTimeout(() => {
              this.setCameraOverlay();
              if (window.isMediaStreamAPISupported) {
                  this.scan();
              }
          }, 1000);

          // To support other browsers who dont have mediaStreamAPI
          this.selectFromPhoto();
      },

    beforeDestroy() {

        this.stopVideo()
    },

    methods: {
      openLoader(val) {
        const self = this;
        self.$f7.dialog.preloader('Seaching Gift Card '+val);
      },
      closeLoader(){
        const self = this;
        self.$f7.dialog.close();
      },
          selectFromPhoto() {
              //Creating the camera element
              let camera = document.createElement('input');
              camera.setAttribute('type', 'file');
              camera.setAttribute('capture', 'camera');
              camera.id = 'camera';
              window.appOverlay.style.borderStyle = '';
              selectPhotoBtn.style.display = 'block';
              this.createFrame();

              //Add the camera and img element to DOM
              let pageContentElement = document.querySelector('.app__layout-content');
              pageContentElement.appendChild(camera);
              pageContentElement.appendChild(frame);

              //Click of camera fab icon
              selectPhotoBtn.addEventListener('click', () => {
                  scanningEle.style.display = 'none';
                  document.querySelector('#camera').click();
              });

              //On camera change
              camera.addEventListener('change', event => {
                  if (event.target && event.target.files.length > 0) {
                    frame.className = 'app__overlay';
                     frame.src = URL.createObjectURL(event.target.files[0]);
                      if (!window.noCameraPermission) scanningEle.style.display = 'block';
                      window.appOverlay.style.borderColor = 'rgb(62, 78, 184)';
                      this.scan(true);
                  }
              });
          },

          createFrame() {
             frame = document.createElement('img');
              frame.src = '';
             frame.id = 'frame';
          },
          scan(forSelectedPhotos = false) {

        if (window.isMediaStreamAPISupported && !window.noCameraPermission) {
            scanningEle.style.display = 'block';
        }

        if (forSelectedPhotos) {
            scanningEle.style.display = 'block';
        }

        QRReader.scan(result => {
            copiedText = result;
            textBoxEle.value = result;
            this.qrCodeText = result;
            textBoxEle.select();
            scanningEle.style.display = 'none';
            // if (isURL(result)) {
            //     dialogOpenBtnElement.style.display = 'inline-block';
            // }
            // dialogElement.classList.remove('app__dialog--hide');
            // dialogOverlayElement.classList.remove('app__dialog--hide');
            const frame= document.querySelector('#frame');
            // if (forSelectedPhotos && frame) frame.remove();
        }, forSelectedPhotos);
    },

           setCameraOverlay() {
        window.appOverlay.style.borderStyle = 'solid';
    },
          //To open result in browser
           openInBrowser() {
        console.log('Result: ', copiedText);
        window.open(copiedText, '_blank', 'toolbar=0,location=0,menubar=0');
        copiedText = null;
        this.hideDialog();
    },
        stopVideo(){
            QRReader.stop()
        },


    //Hide dialog
     hideDialog() {
        copiedText = null;
        textBoxEle.value = '';

        if (!window.isMediaStreamAPISupported) {
            this.frame.src = '';
            this.frame.className = '';
        }

        dialogElement.classList.add('app__dialog--hide');
        dialogOverlayElement.classList.add('app__dialog--hide');
        this.qrCodeText='';
        this.scan();
    },


    }

  }
</script>
