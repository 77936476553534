import request from '../components/utils/request';

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data,
  });
}

export function getInfo(token) {
  return request({
    url: '/auth/user',
    method: 'get',
  });
}

export function getGiftCard(uuid) {
  return request({
    url: '/get-gift-cards-validation/'+uuid,
    method: 'get',
  });
}

export function validateGiftCard(uuid) {
  return request({
    url: '/validate-gift-card/'+uuid,
    method: 'post',
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  });
}
