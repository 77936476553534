<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar  >
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>Gift Card You Validation</f7-nav-title>

<!--      <f7-nav-title-large>Gift Card You Validation</f7-nav-title-large>-->

      <div class="right">
        <a class="link icon-only">
          <i class="icon f7-icons if-not-md">person_circle_fill<span class="badge color-red">1</span></i>
          <i class="icon material-icons md-only">person<span class="badge color-red">5</span></i>
        </a>
      </div>
    </f7-navbar>

    <f7-toolbar tabbar labels :position="'bottom' ">
      <f7-link tab-link="#tab-1" tab-link-active text="Scan" icon-ios="f7:gift" icon-aurora="f7:envelope_fill" icon-md="material:card_giftcard"></f7-link>
      <f7-link tab-link="#tab-2" text="Search" icon-ios="f7:calendar_fill" icon-aurora="f7:search" icon-md="material:search"></f7-link>
      <f7-link tab-link="#tab-3" text="Tab 3" icon-ios="f7:cloud_upload_fill" icon-aurora="f7:cloud_upload_fill" icon-md="material:file_upload"></f7-link>
    </f7-toolbar>

    <!-- Toolbar-->



    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button class="action-btn"  href="/scan-qr/" fill>Scan QR code</f7-button>



        </f7-col>

      </f7-row>
    </f7-block>


  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  data(){
    return {

    }
  },
  computed:{
    ...mapState(['accessToken'])
  }
}
</script>
<style>

  .action-btn {
    margin-bottom: 10px;
  }
</style>
