import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import Vue from "vue";
import { getToken, removeToken } from "../components/utils/auth";
import { getGiftCard } from "../api/auth";


Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = new Vuex.Store({
    state: {
        user: {},
        account: {},
        baseUrl: "",
        accessToken: getToken(),
        currentGiftCard:false
    },
    mutations: {
        setUser (state, user) {
            state.user=user;
        },
        setToken (state,token) {
            state.accessToken=token;
        },
        setGiftCard (state,GiftCard) {
            state.currentGiftCard=GiftCard;
        },
        setAccount (state,account) {
            state.account=account;
        },
        setBaseUrl (state,baseUrl) {
            state.baseUrl=baseUrl;
        },

    },
    actions:{
        async   loadGiftCard({commit},uuid){

            try {
                const {giftCard, account, baseUrl } =  await  getGiftCard(uuid);

                commit("setGiftCard",giftCard);
                commit("setAccount",account);
                commit("setBaseUrl",baseUrl);

              return   giftCard

            } catch (e) {

                throw new Error(e);

            }

        },

      async  logout({commit}){
            removeToken()
            commit("setToken","")

          return true;

        }
    },
    getters:{
        getToken: (state) => {
            return state.accessToken
        }
    },
    plugins: [vuexLocal.plugin]
})

 export  default store;