import axios from 'axios';
import { getToken, setToken } from './auth';
import store from "../../js/store";


// Create axios instance
const service = axios.create({
  baseURL: process.env.MIX_BASE_API,
  timeout: 10000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // Set JWT token
    }

    if(store.getters.getToken){
        config.headers['Authorization'] = 'Bearer ' + store.getters.getToken; // Set JWT token
    }

    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
      console.log(response)
    if (response.headers.authorization) {
      setToken(response.headers.authorization);
     store.commit("setToken",response.headers.authorization);
      response.data.token = response.headers.authorization;
    }

    if(response.data.token){
        setToken(response.data.token);
        store.commit("setToken",response.data.token);
    }

    return response.data;
  },
  error => {
      console.log(error)
    let message = error.message;
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }

    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // });

    return Promise.reject(error);
  },
);

export default service;
