<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
    <f7-login-screen-title>GiftCardYou Login</f7-login-screen-title>
    <f7-list form>
      <f7-list-input
          label="Username"
          type="text"
          placeholder="Your username"
          :value="username"
          @input="username = $event.target.value"
      ></f7-list-input>
      <f7-list-input
          label="Password"
          type="password"
          placeholder="Your password"
          :value="password"
          @input="password = $event.target.value"
      ></f7-list-input>
    </f7-list>
    <f7-list>
      <f7-list-button @click="signIn">Sign In</f7-list-button>
      <f7-block-footer>Some text about login information.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</f7-block-footer>
    </f7-list>
  </f7-page>
</template>
<script>
import { f7Page, f7LoginScreenTitle, f7List, f7ListItem, f7ListButton, f7BlockFooter, f7ListInput } from 'framework7-vue';
import { login } from "../api/auth"

export default {
  components: {
    f7Page,
    f7LoginScreenTitle,
    f7List,
    f7ListItem,
    f7ListButton,
    f7BlockFooter,
    f7ListInput,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    signIn() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      login({email:this.username, password:this.password }).then(res=>{

        console.log(res);
        // this.$store.commit("setToken")
        router.navigate("/")

      }).catch(error=>{
        console.log(error)
        app.dialog.alert(`Username and password is not`,"Login error", () => {
          router.back();
        });
      })




    },
  },
};
</script>