<template>
  <transition name="fade">
    <div v-if="issuedGiftCard.gift_card " class="container">

      <div class="space aligncenter " :style="{'border-color': account.header_background_color}">
        <h1 class="brand-color" :style="{ 'color': account.header_background_color }">{{ issuedGiftCard.gift_card.name }}</h1>

        <div class="gift-card" :style="{ 'background-color': account.header_background_color }">
          <img :src="baseUrl+'/storage/'+issuedGiftCard.gift_card.image">
        </div>

        <h1 class="ammount">${{ issuedGiftCard.gift_card.sales_price | currencyDisplay }}</h1>

        <span class="cta">
            <!--<h2>code to scan</h2>-->
          </span>
        <div ref="qrcode" class="qr-wrapper" />

        <h2>{{ issuedGiftCard.uuid }}</h2>

        <p><small><strong>Terms:</strong><span v-html="issuedGiftCard.gift_card.terms" /></small></p>

        <hr :style="{ 'border-color': account.header_background_color }">

        <f7-row>
          <f7-col>

          <f7-button v-if="!issuedGiftCard.validated" @click="validate()" class="validate-button" fill color="green">Validate card</f7-button>

            <div class="validated-wrapper" v-if="issuedGiftCard.validated">Gift Card Validated</div>

            <f7-button v-if="issuedGiftCard.validated" @click="validate()" class="validate-button" fill color="info">Remove validation</f7-button>

          </f7-col>
        </f7-row>



      </div>



    </div>

  </transition>
</template>
<script>

import {validateGiftCard} from "../api/auth";

validateGiftCard
export default {
  props:[
      "account",
      "issuedGiftCard",
      "baseUrl"
  ],
  data(){
    return {
      loading:false
    }
  },
  methods:{
    openLoader(val) {
      const self = this;
      self.$f7.dialog.preloader('Validating...');
    },
    closeLoader(){
      const self = this;
      self.$f7.dialog.close();
    },

    validate(){
      this.openLoader()
      validateGiftCard(this.issuedGiftCard.uuid).then(res=>{

        this.$store.commit("setGiftCard",res.issuedGiftCard);

        let notificationWithButton = this.$f7.notification.create({
          icon: '<i class="icon info_circle_fill">7</i>',
          title: 'Gift Card Validation',
          subtitle: 'The Gift Card was validated',
          text: '',
          closeButton: true,
        });

        notificationWithButton.open();


        this.closeLoader()
      }).catch(error=>{

        this.closeLoader();
      })

    }
  }
}
</script>

<style scoped lang="scss">

.validate-button {
  width: 100%;
  color: white!important;
  font-size: 18px;
  font-weight: bold;
}

.validated-wrapper{
  padding: 10px;
  border: 2px solid green;
  background: #c9ffc9;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;

}

.clear{clear:both;padding:0;margin:0;}

.half{float:left;width:46%;margin-left:2%;margin-right:2%;margin-bottom:20px;}
img {height:auto;max-width:100%;vertical-align:middle;border:0; -ms-interpolation-mode:bicubic;}
.alignleft{text-align:left;}
.alignright{text-align:right;}
.aligncenter{text-align:center;}


p{text-align:justify;justify-content: left;}
a:link, a:visited {text-decoration:none;color:#28a9e2;outline:0;transition:all 0.3s ease;}
a:hover {color:#59cbfd;}
a:active{color:#59cbfd;}
small{font-size:.8em;margin-bottom:20px;}
strong{font-weight:600;}

.gift-card{width:250px;height:150px;display:block;margin:0 auto;border-radius:12px;background-color:#28a9e2;text-align:center;line-height:150px;margin-bottom:20px;}

.order-details{display:block;width:100%;margin:0 auto; background-color:#fff;padding:20px;
  border-radius:6px;margin-bottom:80px;-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 7px 13px;-moz-box-shadow: rgba(0,0,0,0.3) 0 7px 13px;box-shadow: rgba(0, 0, 0, 0.3) 0 7px 13px;}

.space{max-width:600px;margin:0 auto;
  background-color:#fff;padding:50px;-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 7px 13px;-moz-box-shadow: rgba(0,0,0,0.3) 0 7px 13px;box-shadow: rgba(0, 0, 0, 0.3) 0 7px 13px;
  border:4px solid; border-image-slice: 1;}
.ammount{margin-top:.5em;}
.icon{height:40px;margin-left:15px;margin-right:15px;margin-bottom:20px;}
.cta h2{background-color:#28a9e2;padding:20px;color:#fff;}
hr {width:20%;margin:0 auto;margin-top:3em;margin-bottom:3em;border:1px solid;}

.brand-color{color:#28a9e2;}

@media screen and (max-width: 999px) {
  .container {max-width: 720px;}
}

@media screen and (max-width: 719px) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .container {width: 100%;}
}

/*@import 'https://fonts.googleapis.com/css?family=Titillium+Web:400,700';*/

#restartbtn {
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 20px;
}
.table {
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 100%;
  text-align: center;
}




#hastylink {
  position: fixed;
  bottom:5px;
  left: 0;
  right: 0;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>